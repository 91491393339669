import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'gatsby';

import {toggleLang, getLang} from '../services/lang';
import Layout from '../components/Layout';
import RoomsSlider from '../components/Slices/RoomsSlider';
import * as TXT from '../res/strings';
import * as TXTEN from '../res/strings_en';
import { SERVICIOS_ACCESO_LAGO, SERVICIOS_ACCESO_LAGO2, SERVICIOS_LANCHAS, SERVICIOS_ACCESO_LAGO_MOB, SERVICIOS_ACCESO_LAGO2_MOB, SERVICIOS_LANCHAS_MOB } from '../res/images';

class Services extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'ES',
    }

    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount(){
    if (getLang().language !== this.state.lang) {
      this.setState({lang: getLang().language});
      }
    this.updateWindowDimensions();
  }

  toggleLanguage(lang) {
    toggleLang(lang);
    if (lang !== this.state.lang) {
      this.setState({lang: lang});
    }
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  render() {
    let RollList =[];
    if (this.state.windowWidth >= 1023) {
      RollList = [
        { img_src: SERVICIOS_ACCESO_LAGO, id: "roll-1" },
        { img_src: SERVICIOS_ACCESO_LAGO2, id: "roll-2" },
        { img_src: SERVICIOS_LANCHAS, id: "roll-3" },
      ];
    } else {
      RollList = [
        { img_src: SERVICIOS_ACCESO_LAGO_MOB, id: "roll-1" },
        { img_src: SERVICIOS_ACCESO_LAGO2_MOB, id: "roll-2" },
        { img_src: SERVICIOS_LANCHAS_MOB, id: "roll-3" },
      ];
    }
    const APP_LANG = this.state.lang;
    return (
      <Layout lang={this.state.lang} toggleLanguage={this.toggleLanguage}>
        <div className="section" style={{ marginTop: 100 }}>
          <Container>
            <Row>
              <Col className="text-center title">
                <h1>
                  {APP_LANG === 'EN' ? TXTEN.SERVICES.TITLE1 : TXT.SERVICES.TITLE1}
                </h1>
              </Col>
            </Row>
            <RoomsSlider RollList = {RollList} autoplay={true}/>
            <Row>
							<Col xs="12" md={{ size: 8, offset: 2 }} >
								<p>
									{APP_LANG === 'EN' ? TXTEN.SERVICES.CONTENT_MAIN : TXT.SERVICES.CONTENT_MAIN}
								</p>
							</Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: 6, offset: 3 }} offset-md-3 className="has-text-second" >
                <ul>
                  <li>{APP_LANG === 'EN' ? TXTEN.SERVICES.CONTENT_LISTITEM1 : TXT.SERVICES.CONTENT_LISTITEM1}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.SERVICES.CONTENT_LISTITEM2 : TXT.SERVICES.CONTENT_LISTITEM2}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.SERVICES.CONTENT_LISTITEM3 : TXT.SERVICES.CONTENT_LISTITEM3}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.SERVICES.CONTENT_LISTITEM4 : TXT.SERVICES.CONTENT_LISTITEM4}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.SERVICES.CONTENT_LISTITEM5 : TXT.SERVICES.CONTENT_LISTITEM5}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.SERVICES.CONTENT_LISTITEM6 : TXT.SERVICES.CONTENT_LISTITEM6}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.SERVICES.CONTENT_LISTITEM7 : TXT.SERVICES.CONTENT_LISTITEM7}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.SERVICES.CONTENT_LISTITEM8 : TXT.SERVICES.CONTENT_LISTITEM8}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.SERVICES.CONTENT_LISTITEM9 : TXT.SERVICES.CONTENT_LISTITEM9}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.SERVICES.CONTENT_LISTITEM10 : TXT.SERVICES.CONTENT_LISTITEM10}</li>
                </ul>
              </Col>
            </Row>
            <span className="separator_100" />
						<Row>
              <Col xl="12" className="text-center">
                <Link to="/reserve">
                  <div className="btnAction mt-auto">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.CTA_RESERVE : TXT.BUTTON.CTA_RESERVE}
                  </div>
                </Link>
              </Col>
            </Row>
						<Row>
              <Col xl="12" className="text-center">
                <Link to="/">
                  <div className="btnAction mt-auto">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.BTN_BACK_HOME : TXT.BUTTON.BTN_BACK_HOME}
                  </div>
                </Link>
              </Col>
            </Row>
            <Row />
          </Container>
        </div>
      </Layout>
    );
  }

}

export default Services;